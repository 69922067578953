.form_size {
  max-width: 500px;
  margin: auto;
}

.controllabel{
  float: left;
  font-weight: bold;
  margin-top: 10px;
}

.execute_button{
  background: #f0ad4e;
  font-weight: bold;
  border-color: #f0ad4e;
  color: #fff;
  height: 50px;
  line-height: 40px;
  padding: 0;
  margin-top: 20px;
  width: 200px;
}

.float-right {
  text-align: right;
  margin-top: 15px;
}