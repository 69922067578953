/*SideBar Style */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
#sidebar-wrapper{
  min-height: 100vh !important;
  /* width: 10vw !important; */
  min-width: 14rem;
  padding: 0px !important;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  font-size: .75rem;
  font-weight: 800;
  color: rgba(255,255,255,.4);
  padding: 1rem 0 0.5rem 1rem;
}

#page-content-wrapper {
  float: left;
  padding: 0px;
  flex-grow: 1;
  min-width: 1040px;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.icon{
    height: 45px;
}

.SidebarWrap {
    min-height: 100vh !important;
    min-width: 14rem;
    margin-left: -1rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
  }
  .SidebarWrapOuter {
      min-width: 13rem;
      height: 100%;
      text-align: left;
  }
  
.Sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    min-height: 100vh !important;
    z-index: 100;
    padding: 1.5rem 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    width:14rem;
}

.sidebar-divider {
    border-top: 1px solid rgba(255,255,255,.75);
    margin: 0.25rem 0.25rem 0.5rem 0;
}
.sidebar-divider-first {
  border-top: 1px solid rgba(255,255,255,.75);
  margin: 1rem 1rem 0.5rem 0;
}
.sidebar-divider-secondary{
  margin: 0.7rem 1rem 0.5rem 0;
}
.item-divider {
  margin: 1rem 0.25rem 0.5rem 0;
}


.gliphWrapper{
    margin-right: 0.25rem;
    color: rgba(255,255,255,.3)
}
.active{
    font-weight: bold;
}

.tab-content .active > *{
    font-weight: initial;
}

.sidebar-brand {
    height: 4.375rem;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 800;
    margin: 1rem 1rem 1rem 1rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .05rem;
    z-index: 1;

}

.logo{
    height: 50px;
    margin-left: -5px;
    
}
.title{
    display: flex;
    color: #fff;
    align-items: center;
    list-style: none;
    height: auto;
    text-decoration: none;
    font-size: 0.85rem;
    text-align: center;
    margin-left: 1rem;
}


.sideBarControGroup {
  padding-left: 1rem;
  padding-bottom: .5rem;
}