.App {
  text-align: center;
  max-width: 1400px;
  margin: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-body{
  background: #fff;
  font-family: "Nunito";
  font-size: 1rem;
  display: flex;
  min-width: 1280px;
}
.App-link {
  color: #61dafb;
}

.App-container-desk{
  height: 93vh;
  display: flex;
}



.footer {
  text-align: center;
  margin-bottom: 1vh;
}

.footer-style {
  background-color: #F8F8F8;
  border-top: 1px solid #E7E7E7;
  border-right: 1px solid #E7E7E7;
  
  text-align: center;
  padding: 20px;
  position: fixed;
  bottom: 0;
  height: 70px;
  min-width: 1160px;
  
}

.footer-phantom  {
  display: block;
  width: auto;
  height: 80px;

}


.card{
  border:0px;
}
.card-group {
  display: flex;
  flex-flow: row wrap;
}
.card_section {
  margin: 15px;
  text-align: center;
  border: 1px solid rgba(0,0,0,.3);
}

.row_kpi{
  margin-bottom: 0;
}
.col_kpi{
  display: flex;
}
.col_kpi_mid{
  display: flex;
  max-width: 360px;
}

.col_kpi_small{
  display: flex;
  max-width: 300px;
}


.col_kpi:first-of-type {
  padding-right: 7.5px;
}

.col_kpi:last-of-type {
  padding-left: 7.5px;
}

.col_kpi_central {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.card_kpi{
  width: 100%;
  border: 1px solid rgba(0,0,0,.3);
}

.card_kpi_bottom{
margin-top: 10px;
  width: 100%;
  border: 1px solid rgba(0,0,0,.3);
}

.card .card-header{
  background-color: rgba(0,0,0,.03);
  color: white;
}
.card .card-title {
  font-size: 1rem;
  margin-left: 1.3rem;
  margin-right: 1rem;
  padding-bottom: 0.3rem;
  margin-top: 0.5rem;
  text-align: left;
  font-weight: lighter;
  font-weight: bold;
  color: #007bff;
  display: flex;
  align-items: center;
}

.card .card-title svg {
  margin-right: 0.2rem;
}
.card .card-subtitle {
  text-align: left;
  margin-left: 1.3rem;
  margin-right: 1rem;
}

.card-short {
  max-height: 47vh;
}

.card {
  background-color: #fff;
}
.card .card-value {
  font-size: 1rem;
}

.card .card-target {
  font-size: 1rem;
  font-weight: lighter;
}

/*Widget*/
.cell-value {
  text-align: right;
  font-size: 0.9rem;
}
.cell-label {
  text-align: left;
  font-size: 0.9rem;
}

.cell-value-compact {
  text-align: right;
  font-size: 0.7rem;
}
.cell-label-compact {
  text-align: left;
  font-size: 0.7rem;
}

.cell-label-secondary {
  height: 0.5rem;
}

.cell-header {
  font-weight: bold;
}

.cell-detail {
  cursor: pointer;
}

.row-summary {
  font-weight: bold;
  border-top: 2px solid grey;
  --bs-table-striped-bg: white;
}

.row-summary-secondary {
  --bs-table-striped-bg: white;
}

.cell-emphasis {
  font-weight: bold;
}
.cell-left {
  text-align: left;
}
.cell-center {
  text-align: center;
}
.cell-right {
  text-align: right;
}

.card .card-text {
  padding-left:  0.5rem;
  padding-right:  0.5rem;
  padding-bottom: 1rem;
}

table {
  background-color: #ffffff;
}

.left-col {
  text-align: left;
}

.right-col {
  text-align: right;
}
body, td, tfoot, th, thead, tr {
  border-color: inherit;
  border-style: none;
  border-width: 0;
}
p {
  text-align: left;
}

.squareLegend{
  height: 25px;
  width: 25px;
  background-color: #555;
  border-radius: 50%;
  float: left;

}

/*Cols size*/
.doughnuthContainer{
  margin: 10px 10px 10px 10px;
}

.chart-xs {
  max-height: 200px;
  max-width: 200px;
  margin: auto;
}

.chartplacement {
  max-height: 200px;
  max-width: 200px;
  margin: auto;

}
.chartplacement:nth-child(odd) {
  margin-left: 165px;
}
.chartplacement:nth-child(even) {
  margin-right: 165px;
}

.chart-md {
  height: 300px;
  margin: auto;
}
.chart-md-2 {
  height: 450px;
}



/**Page Jumbotron*/
.jumbotron{
  padding: 4rem 2rem;
  background-color:#e9ecef;
  border-radius:.3rem;
  color: #858796;
}

.jumbotron-section{
  padding:1rem 1rem;
  margin-bottom:0px;
  margin-top: 1vh;
  background-color:rgba(0,0,0,.3);
  color: #fff;
  display: table;
  width: 100%;
  border-radius: 0 3px 3px 0;
}

.jumbotron-kpi{
  padding:0.5rem 0 0 0;
  margin-bottom:0px;
  background-color:transparent;
  color: #858796;
  display: table;
  width: 100%;
  min-height: 60px;
}

.jumbotron-section-wrapper{
  display: table-row;
}

.section-title-left{
  display: table-cell;
  float: left;
  text-align: left;

  display: flex;
  align-items: center;
  min-height: 50px;
}
.section-title-right{
  font-weight: normal;
  display: table-cell;
  text-align: right;
  margin: auto;
  vertical-align: middle;
  float: right;
}

.section-title-right div:first-of-type {
  align-items: center;
}

.section-title-right div svg {
  display: block;
  margin: 0 7px 2px 0;
}

.text-custom-primary {
  color: #007bff;
}

h5, h2 {
  font-weight: 700;
  margin-bottom: 0;
}

.height-full {
  height: 100%;
}

.center-vertical {
  display: flex;
  align-items: center;
}

.card-footer-muted {
  color: #6c757d;
  font-size: 0.8rem;
  text-align: left;
}

.nav-link, .nav-link:hover {
  color: black;
  font-weight: bold;
}

.hidden {
  display: none;
}

.hideelement {
  visibility: hidden;
}
.displayed{
  display: block;
}

.selectForms {
  margin-right: 15px;
}

.selectItemContainer {
  float:left; 
  padding-left:2px;
  min-width: 180px;
  color:gray
}

.selectLeftItemContainer {
  max-width: 190px;
  max-height: 60px;
  padding-bottom:5px;
  color:gray

}

.selectItem {
  min-width: 150px;
  padding-left: 0.800rem;
}

.card-height-xs {
  min-height: 320px;
}

.card-height-s {
  min-height: 350px;
}
.card-height-m {
  min-height: 590px;
}
.card-height-xl {
  min-height: 880px;
}


.showComponent{
  display: grid;
}
.showComponentRow{
  display: talbe-row;
}
.showComponentRowComponent{
  width: max-content;
}

.hideComponent{
  display: none;
}


.PandLDetailLeft{
  text-align: left;
  float: left;
  margin-left: 6em;
}

.PandLDetailRight{
  text-align: right;
  float: right;
  margin-right: 6em;
}

.PandLBold {
  font-weight: bold;
}

.modalGroup {
  min-width: 50%; 
  max-height:400px;
}


.picker-wrapper {
  width: 140px;
  max-width: 140px;
}


.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100%;
}

.react-datepicker__view-calendar-icon input {
  width: 120px;
}

.bg-gradient-primary {
  background-color:#4e73df;
  background-image:linear-gradient(180deg,#4e73df 10%,#4e73df 100%);
  background-size:cover
}

.menuItem {
  display: flex;
  color: rgba(255,255,255,.8);
  align-items: center;
  /* list-style: none; */
  /* min-height:60px; */
  height: auto;
  text-decoration: none;
  font-size: 0.85rem;
  text-align: left;
  padding: 0.2rem 0 0.25rem 1.5rem;
}

.menuItem.active, .menuItem.active svg {
  color: #fff;
  background-color: chocolate;
  pointer-events: none;
}

a.menuItem:hover, a.menuItem:hover svg {
  color: #fff;
  font-weight: bolder;
}



.menuItem {
  display: flex;
  color: rgba(255,255,255,.8);
  align-items: center;
  /* list-style: none; */
  /* min-height:60px; */
  height: auto;
  text-decoration: none;
  font-size: 0.85rem;
  text-align: left;
  padding: 0.2rem 0 0.25rem 1.5rem;

}

.navbar-primary {
  background-color:#4e73df;
  background-image:linear-gradient(180deg,#4e73df 10%,#4e73df 100%);
  background-size:cover;
  padding-top: 0px;
  padding-bottom: 0px;
}

.navlink--item {
  display: flex;
  color: rgba(255,255,255,.8);
  align-items: center;
  /* list-style: none; */
  /* min-height:60px; */
  text-decoration: none;
  font-size: 0.85rem;
  text-align: left;
  padding: 0.2rem 0 0.25rem 1.5rem;
  min-height: 50px;

}

a.navlink--item:hover, a.navlink--item:hover svg {
  color: #fff;
  font-weight: bolder;
}


.nav-link--active, .nav-link--active svg {
  display: flex;
  color: #fff;
  align-items: center;

  background-color: chocolate;
  pointer-events: none;
  min-height: 50px;
  padding: 0.2rem 0 0.25rem 1.5rem;

}

.form_filter {
  display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: start;
    height: 100%;
}
.strikethrough {
  text-decoration: line-through;
  color: #666;
  cursor: pointer;
  font-weight: bold;
}

.regular {
  text-decoration: none;
  color: #000;
  cursor: pointer;
  font-weight: bold;
}
.compact {
  font-size: xx-small;
}


.tabLayout {
  height: 550px;
}

.table-striped>tbody>tr:nth-child(odd)>td, 
.table-striped>tbody>tr:nth-child(odd)>th {
   background-color: #f2f9fd; 
 }