.login-container{
    height: 100vh;
    position: relative;
}

.login-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-color: whitesmoke;
    border: 2px solid #f0ad4e;
    border-radius: 4px;
    padding: 10px;
}

.login-header {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 5px;
}

.login-header h5 {
    margin-top: 5px;
    font-size: 18px;
    font-weight: lighter;
}
.login-button {
    background: #f0ad4e;
    border-color: #f0ad4e;
    color: #fff;
    font-size: 14px;
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding: 0;
}


.common-button {
  background: #f0ad4e;
  border-color: #f0ad4e;
  color: #fff;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  padding: 0;
}

.login-error-box {
  margin-top: 10px;
}