.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #6dc8cf;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
  
.loading-spinner {
  border: 7px solid #f3f3f3;
  border-top: 7px solid #f7ae65;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1.5s linear infinite;
}
  
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}