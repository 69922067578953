
/* Styles applied only to components who render any chart.js graph*/

.graphBlock {
    margin: 0 15px;
}

.tooltip {
  width: 200px;
}
.tooltip-content {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  opacity: 1;
}
.tooltip-title {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
}
.tooltip-body {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  color: whitesmoke;
}
.tooltip-td{
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.tooltip-table {
  width: 240px;
  display:block;
}
.tooltip-thead {
  display: inline-block;
  width: 100%;
  height: 20px;
}
.tooltip-tbody {
  height: 450px;
  display: inline-block;
  width: 100%;
  overflow: auto;
}